import React, { useContext, useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Grid, Wrapper, Container, below } from "../styles"
import { Button } from "../components/Button"
import SEO from "../components/seo"
import ShopifyProduct from "../components/shopify/ShopifyProduct"
import { motion, AnimatePresence } from "framer-motion"
import Icon from "../components/Icon"
import { ProLoginForm } from "../components/forms/ProLoginForm"
import { AuthContext, LoadingSpinner } from "@rachio-npm/rachio-retail-common"
import { ShopifyProduct as IShopifyProduct } from "../lib/shopify/storefront-api-client/types/custom.types"
import { getCollectionByHandle } from "../lib/shopify"

export const Catalog = () => {
  const [shopifyCollection, setShopifyCollection] = useState(null)

  useEffect(() => {
    const fetchCollection = async () => {
      const collection = await getCollectionByHandle("pro-store");
      setShopifyCollection(collection);
    }

    fetchCollection()
  }, [])

  if (!shopifyCollection) return <LoadingSpinner />

  return (
    <motion.div
      data-testid="catalog"
      initial={{ x: 400 }}
      animate={{ x: 0 }}
      exit={{ x: 400 }}
      transition={{ duration: 0.3 }}
    >
      <Wrapper>
        <Container>
          <Grid cols={[1]} style={{ justifyItems: 'center' }}>
            {shopifyCollection.products.map((product: IShopifyProduct) => (
              <div style={{ maxWidth: '528px' }} key={product.id}>
                <ShopifyProduct product={product} />
              </div>
            ))}
          </Grid>
          <p css="text-align: center">
            For special instructions on how to order from Canada, please
            contact <a href="mailto:prosupport@rachio.com">Pro@rachio.com</a>
          </p>
        </Container>
      </Wrapper>
      <Wrapper width="tight" css="text-align: center">
        <h2 css="margin-top: 0">Other Options to Purchase Rachio</h2>
        <p>
          Rachio is available at most irrigation
          distributors; <Link to="/where-to-buy/">click here</Link> to
          see if there is one in your area. Please contact your local
          distributor for best pricing, in-store availability, terms,
          and rewards.
        </p>
      </Wrapper>
      <Wrapper>
        <Container
          as={Grid}
          cols={[1, 1, 2]}
          css="padding-bottom: var(--basePadding);"
        >
          <IconCard css="background: var(--navyBlue)">
            <Icon name="tools" color="var(--white)" />
            <h6>Sales Toolkit</h6>
            <p>
              Want to know more about saving water with a smart sprinkler
              controller? Explore these resources to learn about Rachio.
            </p>
            <Button as={Link} to="/toolkit/" color="transparent">
              View Toolkit
            </Button>
          </IconCard>
          <IconCard css="background: var(--primaryBlue)">
            <Icon name="support" color="var(--white)" />
            <h6>Rachio Support</h6>
            <p>View guides, videos and more for additional support.</p>
            <Button as={Link} to="/support/" color="transparent">
              Pro Support
            </Button>
          </IconCard>
        </Container>
      </Wrapper>
    </motion.div>
  )
}

type ContentProps = {
  data: any
}
export const Content = ({ data }: ContentProps) => {
  const { loggedIn, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingSpinner />
  }

  if (loggedIn) {
    return <Catalog data-test-id="catalog" />
  }

  return (
    <ProLoginForm />
  )
}

const BuyPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Rachio Pro Store"
        description="Shop Rachio's smart sprinkler controller and accessory bundles."
        pathname="/buy/"
      />
      <Wrapper>
        <h1 css="color: var(--primaryColor); text-align: center">
          Rachio Pro Store
        </h1>
        <p className="largest" css="text-align: center">
          Extend your clients’ smart home capabilities to the lawn with the
          controller that sparked a smart watering revolution.
        </p>
        <hr
          css={`
            padding-top: var(--basePadding);
            ${below.medium`
            margin-bottom: 6rem;
          `}
          `}
        />
      </Wrapper>
      <AnimatePresence>
        <Content data={data} />
      </AnimatePresence>
    </>
  )
}

export default BuyPage

const Banner = styled.h4`
  margin: 0;
  text-align: center;
  padding: 2rem;
  background: var(--primaryColor);
  color: var(--white);
`

const IconCard = styled.div`
  padding: var(--basePadding);
  color: var(--white);
  border-radius: var(--cardRaduis);
`