import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Button } from "../Button"
import { Grid, below, media } from "../../styles"
import { useCheckout } from "../../global"
import {
  R3_SHOPIFY_HANDLE,
  R3_PRO_SERIES_3_PACK_HANDLE,
} from "../../lib/shopify/shopify-ids";
import { Link } from "gatsby"
import { ShopifyProductVariant, ShopifyProduct as IShopifyProduct } from "../../lib/shopify/storefront-api-client/types/custom.types"

type Props = {
  product: IShopifyProduct
}

const VariantCard = ({ variant, product, selectedVariant, onSelect }: any) => {
  const isAvailable = selectedVariant.id === variant.id && product.handle !== R3_SHOPIFY_HANDLE
  return (
    <CardButton
      key={variant.id}
      isActive={isAvailable}
      hasVariants={product.variants.length > 1}
      onClick={() => onSelect(variant)}
    >
      <p css="margin: 0">{variant.title}</p>
      <Price product={product} variant={variant} />
      <p className="subtext">{variant.sku}</p>
    </CardButton>
  )
}

const ButtonDisclaimer = styled.p`
  ${media.medium`
    min-height: 130px;
  `}

  text-align: center;
`

const Price = ({ variant, product }: any) => {
  if (product.handle !== R3_SHOPIFY_HANDLE) {
    const styles = {
      margin: 0,
      color: 'var(--primaryColor)'
    }

    return (
      <h4 style={styles}>
        ${variant.price.amount}
      </h4>
    )
  }

  return null;
}

const CartButton = ({ selectedVariant, product,  onAddToCart }: any) => {
  if (product.handle === R3_PRO_SERIES_3_PACK_HANDLE) {
    return (
      <>
        <ButtonDisclaimer>
          Also available in your local distributor. For questions and support,
          email <a href="mailto:prosupport@rachio.com">prosupport@rachio.com</a>.
        </ButtonDisclaimer>
        <Button
          className="buy-button"
          onClick={() => onAddToCart(selectedVariant.id, 1)}
        >
          Buy Now
        </Button>
      </>
    )
  }

  if (product.handle === R3_SHOPIFY_HANDLE) {
    return (
      <>
        <ButtonDisclaimer>
          This product is no longer available. For questions and support,
          email <a href="mailto:prosupport@rachio.com">prosupport@rachio.com</a>.
        </ButtonDisclaimer>
        <Button
          className="buy-button"
          disabled
        >
          Discontinued
        </Button>
      </>
    )
  }

  if (selectedVariant.availableForSale) {
    return (
      <Button
        className="buy-button"
        onClick={() => onAddToCart(selectedVariant.id, 1)}
      >
        Add to Cart
      </Button>
    )
  }

  return (
    <Button className="buy-button" disabled>
      Not Available
    </Button>
  )
}

const ProductTag = ({ product }: any ) => {
  const styles = {
    margin: '0 0 1rem',
    fontSize: 'var(--smallFontSize)'
  };

  if (product.handle === R3_PRO_SERIES_3_PACK_HANDLE) {
    return (
      <p style={styles}>
        <strong>AVAILABLE NOW</strong>
      </p>
    )
  }

  if (product.handle === R3_SHOPIFY_HANDLE) {
    return (
      <p style={styles}>
        <strong>DISCONTINUED</strong>
      </p>
    )
  }

  return (
    <p style={styles}>
      3 Pack Exclusive Pricing
    </p>
  )

}

const ProductTitle = ({ product }: any) => {
  const styles = {
    color: 'var(--primaryColor)',
    marginTop: 0
  }

  if (product.handle === R3_PRO_SERIES_3_PACK_HANDLE) {
    return (
      <Link to="/rachio-pro-series"><h4 style={styles}>{product.title}</h4></Link>
    )
  }

  return (
    <h4 style={styles}>{product.title}</h4>
  )
}

const ShopifyProduct = ({ product }: Props) => {
  if (!product) return null
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0])
  const { addVariantToCart } = useCheckout();

  return (
    <ProductContainer>
      <ProductTag product={product} />
      <ProductTitle product={product} />

      <img
        itemProp="image"
        src={product.media[0].image.src}
        alt={product.media[0].image.altText || `${selectedVariant.title}`}
      />
      <Grid cols={product.variants.length} css="padding: 3rem 0">
        {product.variants.map((variant: ShopifyProductVariant) => (
          <VariantCard
            key={variant.id}
            variant={variant}
            product={product}
            onSelect={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
        ))}
      </Grid>
      <CartButton
        selectedVariant={selectedVariant}
        product={product}
        onAddToCart={addVariantToCart}
      />
    </ProductContainer>
  )
}

export default ShopifyProduct

const ProductContainer = styled.div`
  padding: var(--basePadding);
  background: var(--lightesterGray);
  .buy-button {
    width: 100%;
  }
  ${media.tablet`
    .gatsby-image-wrapper {
      min-height: 295px;
    }
  `}
`

const CardButton = styled.button`
  min-height: 125px;
  text-align: center;
  padding: 2rem;
  outline: none;
  border-radius: var(--cardRadius);
  transition: 0.3s ease border-color;
  ${below.medium`
    padding: 1rem;
  `}
  ${({ isActive, hasVariants }: { isActive: boolean; hasVariants: boolean }) =>
    hasVariants
      ? css`
          border: ${isActive
            ? "2px solid var(--primaryColor)"
            : "1px solid var(--lightestGray)"};
        `
      : css`
          border: 1px solid transparent;
          cursor: auto;
        `};
`
